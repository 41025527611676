<template>
  <div>
    <div class="mobile_footer text_white py-4 px-md-5 px-4">
      <div class="row m-0 justify-content-between align-items-end">
        <div class="col-6 p-0 font5 regular text-left clickable" @click="openPP()">
          [Privacy Policy]
        </div>
        <div class="col-6 p-0 text-right">
          <img src="../../assets/images/more_logo.png" class="mpt_logo" style="width: 40vw; max-width: 40vw;">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MobileFooter',
  methods: {
    openPP () {
      this.$emit('openModalTAndC')
    }
  }

}
</script>
<style scoped>
.mobile_footer {
  height: 120px;
  background-color: var(--blue-black);
  color: var(--dust);
}
.mpt_logo {
  width: 80px;
}
@media (min-width: 768px) {
  .mobile_footer {
    height: 170px;
  }
  .mpt_logo {
    width: 130px;
  }
}
</style>
